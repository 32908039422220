












































import { Component, Mixins } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import UserService from "@/services/UserService";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class ForgotPasswordView extends Mixins(RulesMixin) {
  isValid = true;
  email = "";
  submitted = false;

  private async handleSubmit(): Promise<any> {
    try {
      await UserService.resetPassword(this.email);
      this.$snackbarSuccess(this.$tc("resetLinkSent", 1));
      this.submitted = true;
    } catch (err) {
      this.$snackbarError(this.$tc("auth.resetFailed", 1));
    }
  }
}
